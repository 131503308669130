// UserWait.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Headers from '../../Components/Header';
import Footer from '../../Components/Footer';

 const AboutUs = () => {
    return (
      <>
      <Headers/>
      <div style={{padding:"5% 15%",justifyContent:"center",minHeight:'80vh'}}>
        <h1 style={{ textAlign: "center", marginBottom:"25px" }}>
          Club <span style={{ color: "#ff82bf" }}>Nights</span>
        </h1>
        <p>
          Welcome to Club Nights! We are dedicated to enhancing your club
          and party experiences. Our platform allows users to submit song requests
          for their favorite tracks to be played by the DJ. Simply visit our website
          at <a href="https://clubnights.fun">clubnights.fun</a> and submit your
          song requests. Our DJ will receive your requests and play them during the
          event, ensuring a memorable night for everyone.
        <br></br>  <br></br>
          By using our services, you agree to our terms and conditions. Please read
          our <a href="">Terms and Conditions</a> for more information.
        </p>
      </div>
      <Footer/>
      </>
    );
};

export default AboutUs;
