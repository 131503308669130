// UserWait.js

import React, {useState, useEffect } from 'react';
import Headers from '../../Components/Header';
import Footer from '../../Components/Footer';
import DesktopPage from './DesktopPage';
import SearchDj from '../Users/SearchDJ';
import {  useNavigate } from 'react-router-dom';

 const LandingPage = () => {
  const [isMobile, setIsMobile] = useState(null); // Initial state as null for loading
  const navigate = useNavigate();
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 768px)');
    const handler = (e) => setIsMobile(e.matches);
    mediaQuery.addEventListener('change', handler);
    handler(mediaQuery); // Initial check
    return () => mediaQuery.removeEventListener('change', handler);
  }, []);

  // useEffect(() => {
  //   navigate(`/opendj/100`);
  // }, []);

  return (
    <div>
      {isMobile === null ? (
        <div>Loading...</div> // Or a loading indicator
      ) : isMobile ? 
      // navigate(`/opendj/100`)
      (
        <SearchDj />
      ) 
      : (
        <DesktopPage />
      )}
    </div>
  );
}

export default LandingPage;
