import React from 'react'
import '../Styles/Components/Header.css'


const Header = () => {

  const handleBurgerMenu = () => {
    var x = document.getElementById("myTopnav");
    if (x.className === "header") {
      x.className += " responsive";
    } else {
      x.className = "header";
    }
  }

  return (
    <div class="header" id="myTopnav">
      <a class="logo">Club Nights</a>

      <div class="header-right">
        <a href='/' class="hr" > Home</a>
        {/* <a class="hr"> Contact</a> */}
        <a class="hr" href='/aboutus'>About Us</a>
        <a href="javascript:void(0);" class="icon" onClick={() => {
          handleBurgerMenu()
        }} >
          <i class="fa fa-bars"></i>
        </a>
      </div>
    </div>
  )
}

export default Header