// UserWait.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Headers from '../../Components/Header';
import Footer from '../../Components/Footer';

const Refund = () => {
  return (
    <>
      <Headers />
      <div style={{ padding: "3% 8%", justifyContent: "center",display:"flex",flexDirection:"column", minHeight:"80vh"}}>
        {/* <h1 style={{ textAlign: "center", marginBottom: "25px" }}>
          Club <span style={{ color: "#ff82bf" }}>Nights</span>
        </h1> */}
        <p style={{textAlign:"center",marginBottom:"40px"}}>
          Refund Policy</p>
        <div style={{marginBottom:"20px"}}>This policy outlines the conditions under which refunds may be issued.</div>


        {/* <span  style={{color:"white"}} >Nature of Service</span> */}
        <p style={{marginBottom:"20px"}}>
The song you have paid for is not played on the day you sent the request.</p>

          {/* <span style={{color:"white"}} >Music Genres</span> */}
        <p style={{marginBottom:"20px"}}>Once your requested song has begun playing, no refund will be provided.
DJs have the discretion to choose which part or verse of a song to play. No refund will be given if a different part or verse is played, provided the requested song is played.
</p>

          {/* <span style={{color:"white"}} >Respect for Religious Sentiments</span> */}
        <p style={{marginBottom:"20px"}}>Refunds will not be offered if you mistakenly sent the wrong song request and it was accepted.
Variations in the version of the song played (slowed, fast, lo-fi, remix, etc.) do not qualify for a refund as long as the requested song is played.</p>

          {/* <span style={{color:"white"}} >Data Collection</span> */}
        <p style={{marginBottom:"20px"}}>To request a refund under eligible circumstances, please contact +919050002806 within 7 days of the event.</p>

          {/* <span style={{color:"white"}} >Changes to Refund Policy</span> */}
        <p style={{marginBottom:"20px"}}>We reserve the right to assess each refund request on a case-by-case basis and may require evidence to support your claim.</p>


        <span style={{color:"white"}} ></span>
        <p style={{marginBottom:"20px"}}>By using our website, you agree to abide by our Refund policy.</p>






      </div>
      <Footer/>
    </>
  );
};

export default Refund;
