// UserWait.js

import React, { useEffect } from 'react';
import Headers from '../../Components/Header';
import Footer from '../../Components/Footer';

 const DesktopPage = () => {
  

  useEffect(() => {
    // Redirect to a specific URL when the component loads
    window.location.href = '/home.html';
  }, []);
    return (
      <>
      <Headers/>

      <h1>Loading...</h1>
      
     
      <Footer/>
      </>
    );
};

export default DesktopPage;
