// DJLogin.js

import React, { useState } from 'react';
import '../../Styles/DJ Styles/DJLogin.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../Components/BackButton';
import {  toast } from "react-toastify";
import Header from "../../Components/Header";

const DJLogin = () => {
  const navigate = useNavigate();
  const [djId, setDjId] = useState('');
  const [password, setPassword] = useState('');
  const [djData, setdjData] = useState({
    DjName: '',
    statusLive: '',
    DJId: '',
  });

  const handleLogin = async (e) => {
    e.preventDefault();
    toast.loading("Loading...");
    await axios
      .post(process.env.REACT_APP_BASE_URL+'/dj/login', {
        DjNumber: djId.toLowerCase(),
        Djpassword: password,
      })
      .then((res) => {
        toast.dismiss();
        if (res.data.success === true) {
          toast.success("Logged in successfully", {
            position: toast.POSITION.TOP_CENTER,
          });


          const { _id, DjName, statusLive } = res.data;
          setdjData({ _id, DjName, statusLive });

          // Save DJ data to localStorage
          localStorage.setItem('djData', JSON.stringify({ _id, DjName }));
         navigate('/djportal');
        } else if (res.data.success === false) {
          toast.error(res.data.error, {
            position: toast.POSITION.TOP_CENTER,
          });  
        } else {
          toast.error("Try after sometime!", {
            position: toast.POSITION.TOP_CENTER,
          });        
        }
      })
      .catch((err) => {
        toast.dismiss();
        toast.error("Something went wrong!")
        // console.log(err);
      });


  };

  return (
    <>
    <Header/>
    <div
      style={{
        width: '100%',
        height: '90vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
     
      <div className="djlogin-container">
   
        <div className="firstboxdjlogin">
          <div className="djimgboy">
            <img src={require('../../assets/dj.png')} alt="img-dj" />
          </div>

          <p>
            Welcome to{' '}
            <h1
              style={{
                textAlign: 'center',
                marginTop: 50,
                fontWeight: 'bold',
                fontSize: '23px',
              }}>
              Club <span style={{ color: '#ff82bf' }}>Nights</span>
            </h1>{' '}
          </p>
        </div>
        
        <div className="secondboxdjlogin">
     
        <div className="djbackcard">
              <BackButton />
        </div>
         
          <div className="djimgcard">
            <img src={require('../../assets/djlogo.png')} alt="img-dj" />
          </div>
      
          <h2 className="djlogintitle">DJ Login</h2>
          <form className="djloginform" onSubmit={handleLogin}>
            <div className="dj-login-input-container">
              <label style={{marginRight:"15%"}} className="labeldjlogin" htmlFor="djId">
                DJ ID:
              </label>
              <input
                className="djloginInputs"
                placeholder="Enter DJ ID"
                type="text"
                id="djId"
                value={djId}
                onChange={(e) => setDjId(e.target.value)}
                required
              />
            </div>
            <div className="input-container">
              <label className="labeldjlogin" htmlFor="password">
                Password:
              </label>
              <input
                placeholder="Enter Password here..."
                className="djloginInputs"
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>

            <div className="back_login_wrapper">
              <div style={{display:"contents"}}>
                <button class="custom-btn btn-5" type="submit" style={{background:"#8ce436"}}>
                  <span style={{ color: '#000', marginTop:'0'}}>Login </span>
                </button>
             {/* <div style={{textAlign:"center",cursor:"pointer"}}>  Reset Password | Signup  </div>   */}
             <div style={{ display: "flex", marginTop: "10px" }}>
          <p>Haven't registered yet?</p>
         
          <span  class="cursor-pointer"
            onClick={() => {
              navigate("/enlistclub");
            }} style={{marginLeft:"5px"}}> Sign Up </span>
        </div>
             </div>
            </div>
            
            
          </form>
        </div>
      </div>
    </div>
    </>
  );
};

export default DJLogin;
