// UserWait.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Headers from '../../Components/Header';
import Footer from '../../Components/Footer';

const Privacy = () => {
  return (
    <>
      <Headers />
      <div style={{ padding: "3% 8%", justifyContent: "center",display:"flex",flexDirection:"column", minHeight:"80vh"}}>
        {/* <h1 style={{ textAlign: "center", marginBottom: "25px" }}>
          Club <span style={{ color: "#ff82bf" }}>Nights</span>
        </h1> */}
        <p style={{textAlign:"center",marginBottom:"40px"}}>
          Privacy Policy</p>
        <div style={{marginBottom:"20px"}}>Please read our privacy policy carefully to understand how we collect, use, and protect your information.</div>


        {/* <span  style={{color:"white"}} >Nature of Service</span> */}
        <p style={{marginBottom:"20px"}}>Our website allows users to submit song requests for their favorite tracks to be played by the DJ.</p>

          {/* <span style={{color:"white"}} >Music Genres</span> */}
        <p style={{marginBottom:"20px"}}>We do not endorse or support any specific music genres that may be considered violent or psychologically disturbing.
</p>

          {/* <span style={{color:"white"}} >Respect for Religious Sentiments</span> */}
        <p style={{marginBottom:"20px"}}>We prohibit clubs or DJs from playing songs that disparage the image of any deity, such as Hindu gods.</p>

          {/* <span style={{color:"white"}} >Data Collection</span> */}
        <p style={{marginBottom:"20px"}}>We do not collect any user personal information except mobile number which is used to send otp to confirm the user.</p>

          {/* <span style={{color:"white"}} >Changes to Privacy Policy</span> */}
        <p style={{marginBottom:"20px"}}>We reserve the right to update our privacy policy as necessary. Any changes will be posted on this page.</p>


        <span style={{color:"white"}} ></span>
        <p style={{marginBottom:"20px"}}>By using our website, you agree to abide by our privacy policy and terms of service.</p>






      </div>
      <Footer/>
    </>
  );
};

export default Privacy;
