// Footer.js

import React from 'react';
import '../Styles/Components/Footer.css'

const Footer = () => {
  return (
    <>
    <footer className="footer">
      <div className="container">
        <p>&copy; 2024 ClubNights. All rights reserved.</p>
        <ul className="footer-links">
          <li><a href="/terms">Terms & Conditions</a></li>
          <li><a href="/refundpolicy">Refund Policy</a></li>
          <li><a href="/privacypolicy">Privacy Policy</a></li>
        </ul>
      </div>
    </footer>
 
    </>
  );
}

export default Footer;