// UserWait.js

import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Headers from '../../Components/Header';
import Footer from '../../Components/Footer';

const Terms = () => {
  return (
    <>
      <Headers />
      <div style={{ padding: "3% 8%", justifyContent: "center",display:"flex",flexDirection:"column", minHeight:"80vh"}}>
        {/* <h1 style={{ textAlign: "center", marginBottom: "25px" }}>
          Club <span style={{ color: "#ff82bf" }}>Nights</span>
        </h1> */}
        <p style={{ textAlign: "center", marginBottom: "40px" }}>
          Terms & Conditions</p>
        <div style={{ marginBottom: "20px" }}>Welcome to clubnights.fun, a platform that facilitates song requests by users to be played by DJs. By using our service, you agree to comply with and be bound by the following terms and conditions:</div>


        {/* <span  style={{color:"white"}} >Nature of Service</span> */}
        <p style={{ marginBottom: "20px" }}>
          Our website 'ClubNights.fun' operate as a platform for users to submit song requests which are then forwarded to DJs for potential inclusion in their playlists.
          Song Request.</p>

        {/* <span style={{color:"white"}} >Music Genres</span> */}
        <p style={{ marginBottom: "20px" }}>Club Nights do not guarantee that every song request will be fulfilled by DJs.
        </p>

        {/* <span style={{color:"white"}} >Respect for Religious Sentiments</span> */}
        <p style={{ marginBottom: "20px" }}>Refunds will be issued in accordance with our <a style={{ fontStyle: "italic" }} href="/refundpolicy">Refund Policy.</a></p>

        {/* <span style={{color:"white"}} >Data Collection</span> */}
        <p style={{ marginBottom: "20px" }}>DJs have the discretion to choose which songs to play and in what order.
          DJs may select specific parts or versions of songs at their discretion.</p>

        {/* <span style={{color:"white"}} >Changes to Terms Policy</span> */}
        <p style={{ marginBottom: "20px" }}>Clubnights.fun is not liable for any damages arising from the use or inability to use our service, including but not limited to direct, indirect, incidental, consequential, or punitive damages.</p>

          <p>This website is owned & managed by Arun kumar manoj kumar </p>

        <span style={{ color: "white" }} ></span>
        <p style={{ marginBottom: "20px" }}>By using our website, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.</p>






      </div>
      <Footer />
    </>
  );
};

export default Terms;
